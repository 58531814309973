/* Apple SD Gothic Neo */
@import "https://cdn.jsdelivr.net/npm/font-applesdgothicneo@1.0.3/css/all.min.css";

/* Noto Sans KR */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;700;900&display=swap');

/* 지마켓 산스 */
@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSansLight';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff') format('woff');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSansBold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 나눔 고딕 */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

/* 나눔 명조 */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');

/* 나눔 스퀘어 */
@font-face {
  font-family: 'NanumSquare';
  src: url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css') format('css');
  font-weight: normal;
  font-style: normal;
}

/* 쿠키런 */
@font-face {
  font-family: 'CookieRunOTF';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/CookieRunOTF-Bold00.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'CookieRunOTF';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/CookieRun-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CookieRunOTF';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/CookieRunOTF-Black00.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 만화진흥원체 */
@font-face {
  font-family: 'KOMACON';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.2/KOMACON.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 카페24 빛나는 별 */
@font-face {
  font-family: 'Cafe24Shiningstar';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Shiningstar.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 산돌 삼립호빵 아웃라인 */
@font-face {
  font-family: 'SDSamliphopangche_Outline';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Outline.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 배민 도현체 */
@font-face {
  font-family: 'BMDOHYEON';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 잉크립퀴드체 */
@font-face {
  font-family: 'InkLipquid';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/InkLipquid.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* 블랙 한 한스 */
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
