
.spacer {
  flex-grow: 1 !important;
};

.fill-height {
  height: 100%;
};
.fill-width {
  width: 100%;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.d-none {
  display: none;
};

.d-inline {
  display: inline;
};

.d-inline-block {
  display: inline-block;
};

.d-block {
  display: block;
};

.d-table {
  display: table;
};

.d-table-row {
  display: table-row;
};

.d-table-cell {
  display: table-cell;
};

.d-flex {
  display: flex;
};

.d-grid {
  display: grid;
}

.d-inline-flex {
  display: inline-flex;
};

.flex-fill {
  flex: 1 1 auto;
};

.flex-row {
  flex-direction: row;
};

.flex-column {
  flex-direction: column;
};

.flex-row-reverse {
  flex-direction: row-reverse;
};

.flex-column-reverse {
  flex-direction: column-reverse;
};

.flex-grow-0 {
  flex-grow: 0;
};

.flex-grow-1 {
  flex-grow: 1;
};

.flex-shrink-0 {
  flex-shrink: 0;
};

.flex-shrink-1 {
  flex-shrink: 1;
};

.flex-wrap {
  flex-wrap: wrap;
};

.flex-nowrap {
  flex-wrap: nowrap;
};

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
};

.justify-start {
  justify-content: flex-start;
};

.justify-end {
  justify-content: flex-end;
};

.justify-center {
  justify-content: center;
};

.justify-space-between {
  justify-content: space-between;
};

.justify-space-around {
  justify-content: space-around;
};

.align-start {
  align-items: flex-start;
};

.align-end {
  align-items: flex-end;
};

.align-center {
  align-items: center;
};

.align-baseline {
  align-items: baseline;
};

.align-stretch {
  align-items: stretch;
};

.align-content-start {
  align-content: flex-start;
};

.align-content-end {
  align-content: flex-end;
};

.align-content-center {
  align-content: center;
};

.align-content-space-between {
  align-content: space-between;
};

.align-content-space-around {
  align-content: space-around;
};

.align-content-stretch {
  align-content: stretch;
};

.align-self-auto {
  align-self: auto;
};

.align-self-start {
  align-self: flex-start;
};

.align-self-end {
  align-self: flex-end;
};

.align-self-center {
  align-self: center;
};

.align-self-baseline {
  align-self: baseline;
};

.align-self-stretch {
  align-self: stretch;
};