$grid-breakpoints: (
  'xs': 0,
  'sm': 600px,
  'md': 960px,
  'lg': 1280px,
  'xl': 1920px
);
$grid-columns: 12 !default;
$spacer : 16px;
$container-max-widths: (
  'md': map-get($grid-breakpoints, 'md') * 0.9375,
  'lg': map-get($grid-breakpoints, 'lg') * 0.9375,
  'xl': map-get($grid-breakpoints, 'xl') * 0.9375
);

$spacers: () !default;
// 4, 8, 12, 16, 20 ... 100
@for $i from 0 through 25 {
  $spacers: map-merge($spacers, ($i: $spacer / 4 * $i))
};

// Colors
$main-font-color: #3D4046;
$sub-font-color: #53585F;
$small-font-color: #626871;
$placeholder-color: #737B86;
$disabled-color : #99A0A9;

$bg1-color: #FFFFFF;
$bg2-color: #F9FAFB;
$bg3-color: #EDF1F6;

$primary-color: #006FFF;
$secondary1-color: #3399FF;
$secondary2-color: #000A29;
$secondary3-color: #CC9966;

$warning-color: #FFAB00;
$success-color: #27C28A;
$error-color: #E65C5C;

$icon-color: #7E8696;
$icon-middle-color: #CAD4E7;
$divider-color: #DCE0E8;

$direction-list: 'top', 'right', 'bottom', 'left';
