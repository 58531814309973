$colors: (
  'main': $main-font-color,
  'sub': $sub-font-color,
  'small': $small-font-color,
  'placeholder': $placeholder-color,
  'disabled': $disabled-color,

  'bg1': $bg1-color,
  'bg2': $bg2-color,
  'bg3': $bg3-color,
 
  'primary': $primary-color,
  'secondary1': $secondary1-color,
  'secondary2': $secondary2-color,
  'secondary3': $secondary3-color,

  'warning': $warning-color,
  'success': $success-color,
  'error': $error-color,

  'icon': $icon-color,
  'icon-middle': $icon-middle-color,
  'divider': $divider-color,
  
);

@each $name, $color in $colors {
  .text-color-#{$name} {
    color: $color;
  };

  .bg-color-#{$name} {
    background-color: $color;
  }

  .border-#{$name} {
    border: 1px solid $color;
  };

  .hover-bg-color-#{$name} {
    &:hover {
      background-color: $color;
    }
  }

  @each $direction in $direction-list {
    .border-#{$direction}-#{$name} {
      border-#{$direction}: 1px solid $color;
    };
  };
};

