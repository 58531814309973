$spacer-utils: (
  "margin": (
    property: margin,
    class: ma,
  ),
  "margin-x": (
    property: (margin-right, margin-left),
    class: mx,
  ),
  "margin-y": (
    property: (margin-top, margin-bottom),
    class: my,
  ),
  "margin-top": (
    property: margin-top,
    class: mt,
  ),
  "margin-right": (
    property: margin-right,
    class: mr,
  ),
  "margin-bottom": (
    property: margin-bottom,
    class: mb,
  ),
  "margin-left": (
    property: margin-left,
    class: ml,
  ),
  "padding": (
    property: padding,
    class: pa,
  ),
  "padding-x": (
    property: (padding-right, padding-left),
    class: px,
  ),
  "padding-y": (
    property: (padding-top, padding-bottom),
    class: py,
  ),
  "padding-top": (
    property: padding-top,
    class: pt,
  ),
  "padding-right": (
    property: padding-right,
    class: pr,
  ),
  "padding-bottom": (
    property: padding-bottom,
    class: pb,
  ),
  "padding-left": (
    property: padding-left,
    class: pl,
  ),
  "gap": (
    property: gap,
    class: gap,
  ),
);


@each $index, $object in $spacer-utils {
  $class: map-get($object, class);
  $propertys: map-get($object, property);

  @for $i from 0 through 25 {
    .#{$class}-#{$i} {
      @if type-of($propertys) == 'string' {
        #{$propertys}: map-get($spacers, $i) !important;
      }
      @if type-of($propertys) == 'list' {
        @each $property in $propertys {
          #{$property}: map-get($spacers, $i) !important;
        }
      }
    }
  }
};

.ma-auto {
  margin: auto;
}
